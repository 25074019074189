import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('`useUser` must be within a `UserProvider`');
  }
  return context;
};

export default useAuth;
