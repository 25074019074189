import { Auth } from 'aws-amplify';
import { Lambda } from 'aws-sdk';
import { useContext } from 'react';

import { UpdateContext } from '../context/UpdateContext';

import useAuth from './useAuth';
import useEnv from './useEnv';
import useSpiderIdentification from './useSpiderIdentification';

const useUpdateForContext = () => {
  const [env] = useEnv();
  const auth = useAuth();
  const [spiderSerial, spiderPin, , , spiderIdentificationValid] = useSpiderIdentification();

  const sync = async (main, bootloader, coprocessor) => {
    if (env && auth.user && spiderIdentificationValid) {
      return Auth.currentCredentials()
        .then((credentials) => {
          if (!credentials.authenticated) {
            return Promise.reject('Not authenticated');
          }
          const lambda = new Lambda({
            apiVersion: '2015-03-31',
            region: 'us-west-2',
            credentials: Auth.essentialCredentials(credentials),
          });
          console.log(
            'Payload:',
            JSON.stringify({
              body: JSON.stringify({
                serialNumber: spiderSerial,
                PIN: spiderPin,
                mainFirmwareVersion: main,
                bootloaderFirmwareVersion: bootloader,
                coprocessorFirmwareVersion: coprocessor,
                stage: 'wiredUpdate',
              }),
            })
          );
          return lambda
            .invoke({
              FunctionName: `update-post-${env}`,
              Payload: JSON.stringify({
                body: JSON.stringify({
                  serialNumber: spiderSerial,
                  PIN: spiderPin,
                  mainFirmwareVersion: main,
                  bootloaderFirmwareVersion: bootloader,
                  coprocessorFirmwareVersion: coprocessor,
                  stage: 'wiredUpdate',
                }),
              }),
            })
            .promise()
            .then((a) => {
              let payload = JSON.parse(a.Payload);
              if (payload.statusCode < 200 || payload.statusCode >= 300) {
                return Promise.reject(payload.body || payload.statusCode);
              }
            });
        })
        .catch((err) => {
          console.log('Error during status update', err);
          return Promise.reject(err);
        });
    } else {
      return Promise.reject('Not logged in');
    }
  };

  return {
    sync,
  };
};

const useUpdate = () => {
  const v = useContext(UpdateContext);
  return v;
};
export { useUpdateForContext };
export default useUpdate;
