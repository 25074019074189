import { buf as crc32 } from 'crc-32';
import Quaternion from 'quaternion';
import {
  ProtobufEventPayloadEventRegistration,
  ProtobufEventHeader,
  ProtobufEventPayloadOperatingMode,
  ProtobufEventPayloadPoint,
  ProtobufEventPayloadDataBlock,
  ProtobufEventPayloadSatelliteNetwork,
  ProtobufEventPayloadPower,
  ProtobufEventPayloadSnifferData,
  ProtobufEventPayloadSettingsMain,
  ProtobufEventPayloadSettingsKeypad,
  ProtobufEventPayloadSettingsGpi,
  ProtobufSettingsUserMainV1,
  ProtobufSettingsInternalMainV1,
  ProtobufEventPayloadExternalDeviceState,
  ProtobufEventPayloadPeripheral,
  ProtobufEventPayloadGpi,
  ProtobufEventPayloadAhrsDiagnostic,
  ProtobufEventPayloadIns,
} from './protobuf';
import { BufferReader } from 'protobufjs';
import { createBlockEncoder } from 'ucobs';

let id = 0;
let Qs;
let Qa;
let Qm;

function encodeProtobufProtocol(eventType, payload = new Uint8Array(0)) {
  const protobufHeader = ProtobufEventHeader.fromObject({
    eventType: eventType,
    payloadLength: payload.length,
    payloadCrc: crc32(payload) >>> 0,
  });

  const protobufHeaderBinary = Uint8Array.from(
    ProtobufEventHeader.encodeDelimited(protobufHeader).finish()
  );

  const packet = new Uint8Array(2 + protobufHeaderBinary.length + payload.length + 4);
  const idArray = new Uint8Array([id & 0xff, (id >> 8) & 0xff]);
  id++;

  packet.set(idArray);
  packet.set(protobufHeaderBinary, 2);
  packet.set(payload, 2 + protobufHeaderBinary.length);

  const crc = crc32(packet.slice(0, packet.length - 4)) >>> 0;
  const crcArray = new Uint8Array([
    crc & 0xff,
    (crc >> 8) & 0xff,
    (crc >> 16) & 0xff,
    (crc >> 24) & 0xff,
  ]);

  packet.set(crcArray, 2 + protobufHeaderBinary.length + payload.length);

  let stream;
  const encode = createBlockEncoder((data) => {
    stream = data;
  });
  encode(packet);

  return stream;
}

export function encodeEventRegistration() {
  const protobuf = ProtobufEventPayloadEventRegistration.fromObject({
    add: true,
    eventType: [
      ProtobufEventPayloadEventRegistration.EventType.kSettingsGetResponseMain,
      ProtobufEventPayloadEventRegistration.EventType.kPowerGetResponse,
      ProtobufEventPayloadEventRegistration.EventType.kPowerGood,
      ProtobufEventPayloadEventRegistration.EventType.kPowerBackup,
      ProtobufEventPayloadEventRegistration.EventType.kPowerCritical,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeGetResponse,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeTrackingActive,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeTrackingInactive,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeSosActivated,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeSosActive,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeSosDeactivated,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeSosInactive,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeWatchActivated,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeWatchActive,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeWatchDeactivated,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeWatchInactive,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeMarkActivated,
      ProtobufEventPayloadEventRegistration.EventType.kOperatingModeMarkActive,
      ProtobufEventPayloadEventRegistration.EventType.kSettingsGetResponseKeypad,
      ProtobufEventPayloadEventRegistration.EventType.kSettingsGetResponseGpi,
      ProtobufEventPayloadEventRegistration.EventType.kPeripheralHostGetResponse,
      ProtobufEventPayloadEventRegistration.EventType.kPeripheralHostConnected,
      ProtobufEventPayloadEventRegistration.EventType.kPeripheralHostDisconnected,
      ProtobufEventPayloadEventRegistration.EventType.kPoint,
      ProtobufEventPayloadEventRegistration.EventType.kInertialNavigationSystem,
      ProtobufEventPayloadEventRegistration.EventType.kExternalDeviceStateGetResponse,
      ProtobufEventPayloadEventRegistration.EventType.kExternalDeviceStateUpdate,
      ProtobufEventPayloadEventRegistration.EventType.kGpiGetResponse,
      ProtobufEventPayloadEventRegistration.EventType.kGpiUpdate,
      ProtobufEventPayloadEventRegistration.EventType.kNavigationDiagnosticGetResponseFixes,
      ProtobufEventPayloadEventRegistration.EventType
        .kNavigationDiagnosticGetResponseConstellations,
      ProtobufEventPayloadEventRegistration.EventType.kDataBlockDiagnosticGetResponseStorage,
      ProtobufEventPayloadEventRegistration.EventType
        .kSatelliteNetworkDiagnosticGetResponseSignalQuality,
      ProtobufEventPayloadEventRegistration.EventType
        .kSatelliteNetworkDiagnosticGetResponseTransmissions,
    ],
  });
  const protobufBinary = Uint8Array.from(
    ProtobufEventPayloadEventRegistration.encode(protobuf).finish()
  );
  return encodeProtobufProtocol(
    ProtobufEventHeader.EventType.kEventRegistrationRequest,
    protobufBinary
  );
}

export function encodeEventStatus() {
  return encodeProtobufProtocol(ProtobufEventHeader.EventType.kExternalDeviceStateGetRequest);
}

export function encodeEventSettings() {
  return encodeProtobufProtocol(ProtobufEventHeader.EventType.kSettingsGetRequest);
}

export function encodeEventMode() {
  return encodeProtobufProtocol(ProtobufEventHeader.EventType.kOperatingModeGetRequest);
}

export function encodeEventPower() {
  return encodeProtobufProtocol(ProtobufEventHeader.EventType.kPowerGetRequest);
}

export function encodeEventPeripheral() {
  return encodeProtobufProtocol(ProtobufEventHeader.EventType.kPeripheralHostGetRequest);
}

export function encodeSetManual(z, x, y) {
  const Qm = Quaternion.fromEuler(
    (z / 180) * Math.PI,
    (y / 180) * Math.PI,
    (x / 180) * Math.PI,
    'ZYX'
  );
  const protobuf = ProtobufSettingsUserMainV1.fromObject({
    manualInstallCorrection:
      ProtobufSettingsUserMainV1.InstallOrientation.kInstallOrientationManual,
    manualInstallCorrectionW: Qm.w,
    manualInstallCorrectionX: Qm.x,
    manualInstallCorrectionY: Qm.y,
    manualInstallCorrectionZ: Qm.z,
  });
  const protobufBinary = Uint8Array.from(ProtobufSettingsUserMainV1.encode(protobuf).finish());
  return encodeProtobufProtocol(
    ProtobufEventHeader.EventType.kSettingsUserMainWrite,
    protobufBinary
  );
}

export function encodeDeleteManual() {
  const protobuf = ProtobufSettingsUserMainV1.fromObject({
    manualInstallCorrection: ProtobufSettingsUserMainV1.InstallOrientation.kInstallOrientationNone,
  });
  const protobufBinary = Uint8Array.from(ProtobufSettingsUserMainV1.encode(protobuf).finish());
  return encodeProtobufProtocol(
    ProtobufEventHeader.EventType.kSettingsUserMainWrite,
    protobufBinary
  );
}

export function encodeResetOrientation() {
  const protobuf = ProtobufSettingsInternalMainV1.fromObject({
    installOrientation: ProtobufSettingsUserMainV1.InstallOrientation.kInstallOrientationNone,
    //installOrientation:
    //  ProtobufSettingsUserMainV1.InstallOrientation.kInstallOrientationAutoLevelAndYaw,
    //installPitchOffsetRad: 0,
    //installRollOffsetRad: 0,
    //installYawOffsetRad: 0,
  });
  const protobufBinary = Uint8Array.from(ProtobufSettingsInternalMainV1.encode(protobuf).finish());
  return encodeProtobufProtocol(
    ProtobufEventHeader.EventType.kSettingsInternalMainWrite,
    protobufBinary
  );
}

export function encodeEventPointDiagnostics(summaries) {
  const protobuf = ProtobufEventPayloadPoint.fromObject({
    diagnosticRequest: {
      maxSummaries: summaries,
      fixes: true,
      constellations: true,
    },
  });
  const protobufBinary = Uint8Array.from(ProtobufEventPayloadPoint.encode(protobuf).finish());
  return encodeProtobufProtocol(
    ProtobufEventHeader.EventType.kNavigationDiagnosticGetRequest,
    protobufBinary
  );
}

export function encodeEventBlockDiagnostics(type, summaries) {
  const protobuf = ProtobufEventPayloadDataBlock.fromObject({
    dataType: type,
    diagnosticRequest: {
      maxSummaries: summaries,
      storage: true,
    },
  });
  const protobufBinary = Uint8Array.from(ProtobufEventPayloadDataBlock.encode(protobuf).finish());
  return encodeProtobufProtocol(
    ProtobufEventHeader.EventType.kDataBlockDiagnosticGetRequest,
    protobufBinary
  );
}

export function encodeEventSatDiagnostics(summaries) {
  const protobuf = ProtobufEventPayloadSatelliteNetwork.fromObject({
    diagnosticRequest: {
      maxSummaries: summaries,
      signalQuality: true,
      transmissions: true,
    },
  });
  const protobufBinary = Uint8Array.from(
    ProtobufEventPayloadSatelliteNetwork.encode(protobuf).finish()
  );
  return encodeProtobufProtocol(
    ProtobufEventHeader.EventType.kSatelliteNetworkDiagnosticGetRequest,
    protobufBinary
  );
}

function decodePower(payload, setSpider) {
  const power = ProtobufEventPayloadPower.decode(payload);
  setSpider((spider) => ({ ...spider, power }));
}

function decodeOperatingMode(payload, setSpider) {
  const operatingMode = ProtobufEventPayloadOperatingMode.decode(payload);
  setSpider((spider) => ({ ...spider, operatingMode }));
}

function decodeSniffer(payload, setSpider) {
  const snifferData = ProtobufEventPayloadSnifferData.decode(payload);
  setSpider((spider) => ({ ...spider, snifferData }));
}

function decodeSetingsMain(payload, setSpider) {
  const settingsMain = ProtobufEventPayloadSettingsMain.decode(payload);
  Qa = new Quaternion([
    settingsMain.autoInstallOrientationW,
    settingsMain.autoInstallOrientationX,
    settingsMain.autoInstallOrientationY,
    settingsMain.autoInstallOrientationZ,
  ]);
  Qm = new Quaternion([
    settingsMain.manualInstallCorrectionW,
    settingsMain.manualInstallCorrectionX,
    settingsMain.manualInstallCorrectionY,
    settingsMain.manualInstallCorrectionZ,
  ]);
  setSpider((spider) => ({ ...spider, settingsMain }));
}

function decodeSettingsKeypad(payload, setSpider) {
  const settingsKeypad = ProtobufEventPayloadSettingsKeypad.decode(payload);
  setSpider((spider) => ({ ...spider, settingsKeypad }));
}

function decodeSettingsGpi(payload, setSpider) {
  const settingsGpi = ProtobufEventPayloadSettingsGpi.decode(payload);
  setSpider((spider) => ({ ...spider, settingsGpi }));
}

function decodeExternalDevice(payload, setSpider) {
  const externalDeviceState = ProtobufEventPayloadExternalDeviceState.decode(payload);
  setSpider((spider) => ({ ...spider, externalDeviceState }));
}

function decodePeripheral(payload, setSpider) {
  const peripheral = ProtobufEventPayloadPeripheral.decode(payload);
  setSpider((spider) => ({ ...spider, peripheral }));
}

function decodePoint(payload, setSpider) {
  const point = ProtobufEventPayloadPoint.decode(payload);
  setSpider((spider) => ({ ...spider, point }));
}

function decodeGpi(payload, setSpider) {
  const gpi = ProtobufEventPayloadGpi.decode(payload);
  setSpider((spider) => ({ ...spider, gpi }));
}

function decodeIns(payload, setSpider) {
  const ins = ProtobufEventPayloadIns.decode(payload);
  Qs = new Quaternion([ins.qW, ins.qX, ins.qY, ins.qZ]);
  const angles = {};
  const spiderAngles = Qs.toEuler('ZYX').map((angle) => (angle * 180) / Math.PI);
  const autoInstallAngles = Qa.toEuler('ZYX').map((angle) => (angle * 180) / Math.PI);
  const manualCorrectionAngles = Qm.toEuler('ZYX').map((angle) => (angle * 180) / Math.PI);
  const Qc = Qm.inverse().mul(Qa);
  //const correctedAngles = Qc.toEuler('ZYX').map((angle) => (angle * 180) / Math.PI);

  const aircraftAngles = Qs.mul(Qc.inverse())
    .toEuler('ZYX')
    .map((angle) => (angle * 180) / Math.PI);

  angles.spider = {
    roll: Math.round(spiderAngles[2]),
    pitch: Math.round(spiderAngles[1]),
    yaw: Math.round(spiderAngles[0]),
  };

  angles.autoInstall = {
    roll: Math.round(autoInstallAngles[2]),
    pitch: Math.round(autoInstallAngles[1]),
    yaw: Math.round(autoInstallAngles[0]),
  };

  angles.manualCorrection = {
    roll: Math.round(manualCorrectionAngles[2]),
    pitch: Math.round(manualCorrectionAngles[1]),
    yaw: Math.round(manualCorrectionAngles[0]),
  };

  angles.aircraft = {
    roll: Math.round(aircraftAngles[2]),
    pitch: Math.round(aircraftAngles[1]),
    yaw: Math.round(aircraftAngles[0]),
  };

  setSpider((spider) => ({ ...spider, ins, angles }));
}

function decodeNavigationDiagnostic(payload, setSpider) {
  const pointDiagnostic = ProtobufEventPayloadPoint.decode(payload);
  const summaryUptime = Math.round(
    (pointDiagnostic.diagnosticResponse?.summaryUptimeMs ?? 0) / 1000
  );
  const summaryPeriod = pointDiagnostic.diagnosticResponse?.summaryPeriodS ?? 0;
  if (pointDiagnostic.diagnosticResponse?.constellations) {
    const gnss = new Map();
    let uptime = summaryUptime;
    let summaries = pointDiagnostic.diagnosticResponse?.summaries ?? 0;
    while (summaries.length >= 3) {
      const summary = summaries.slice(0, 3);
      summaries = summaries.slice(3);
      const numSats = summary[0];
      const locked = summary[1];
      const bestSignal = summary[2];
      gnss.set(uptime, { numSats, locked, bestSignal });
      uptime -= summaryPeriod;
    }
    setSpider((spider) => ({
      ...spider,
      gnss: Object.assign(spider.gnss ?? {}, Object.fromEntries(gnss)),
      pointConstellations: pointDiagnostic,
    }));
  } else {
    const fixes = new Map();
    let uptime = summaryUptime;
    let summaries = pointDiagnostic.diagnosticResponse?.summaries ?? 0;
    while (summaries.length >= 5) {
      const summary = summaries.slice(0, 5);
      summaries = summaries.slice(5);
      const timeout = summary[0];
      const invalid = summary[1];
      const time = summary[2];
      const fix2d = summary[3];
      const fix3d = summary[4];
      fixes.set(uptime, { timeout, invalid, time, fix2d, fix3d });
      uptime -= summaryPeriod;
    }
    setSpider((spider) => ({
      ...spider,
      fixes: Object.assign(spider.fixes ?? {}, Object.fromEntries(fixes)),
      pointFixes: pointDiagnostic,
    }));
  }
}

function decodeInsDiagnostics(payload, setSpider) {
  const ahrsDiagnostic = ProtobufEventPayloadAhrsDiagnostic.decode(payload);
  setSpider((spider) => ({ ...spider, ahrsDiagnostic }));
}

function decodeBlockDiagnostics(payload, setSpider) {
  const dataBlock = ProtobufEventPayloadDataBlock.decode(payload);
  const dataType = 'dataBlock' + (dataBlock.dataType ?? 0);
  const block = 'block' + (dataBlock.dataType ?? 0);
  const summaryUptime = Math.round((dataBlock.diagnosticResponse?.summaryUptimeMs ?? 0) / 1000);
  const summaryPeriod = dataBlock.diagnosticResponse?.summaryPeriodS ?? 0;

  const blocks = new Map();
  let uptime = summaryUptime;
  let summaries = dataBlock.diagnosticResponse?.summaries ?? 0;
  while (summaries.length >= 8) {
    const summary = summaries.slice(0, 8);
    summaries = summaries.slice(8);
    const size = summary[0] + (summary[1] << 8);
    const written = summary[2] + (summary[3] << 8);
    const consumed = summary[4] + (summary[5] << 8);
    const discarded = summary[6] + (summary[7] << 8);
    blocks.set(uptime, { size, written, consumed, discarded });
    uptime -= summaryPeriod;
  }
  setSpider((spider) => ({
    ...spider,
    [block]: Object.assign(spider[block] ?? {}, Object.fromEntries(blocks)),
    [dataType]: dataBlock,
  }));
}

function decodeSatDiagnostics(payload, setSpider) {
  const satelliteNetwork = ProtobufEventPayloadSatelliteNetwork.decode(payload);
  const summaryUptime = Math.round(
    (satelliteNetwork.diagnosticResponse?.summaryUptimeMs ?? 0) / 1000
  );
  const summaryPeriod = satelliteNetwork.diagnosticResponse?.summaryPeriodS ?? 0;
  if (satelliteNetwork.diagnosticResponse?.transmissions) {
    const transmissions = new Map();
    let uptime = summaryUptime;
    let summaries = satelliteNetwork.diagnosticResponse?.summaries ?? 0;
    while (summaries.length >= 6) {
      const summary = summaries.slice(0, 6);
      summaries = summaries.slice(6);
      const ok = summary[0] + (summary[1] << 8);
      const fail = summary[2] + (summary[3] << 8);
      const error = summary[4] + (summary[5] << 8);
      transmissions.set(uptime, { ok, fail, error });
      uptime -= summaryPeriod;
    }
    setSpider((spider) => ({
      ...spider,
      transmissions: Object.assign(spider.transmissions ?? {}, Object.fromEntries(transmissions)),
      satelliteNetworkTransmissions: satelliteNetwork,
    }));
  } else {
    const csq = new Map();
    let uptime = summaryUptime;
    let summaries = satelliteNetwork.diagnosticResponse?.summaries ?? 0;
    while (summaries.length >= 6) {
      const summary = summaries.slice(0, 6);
      summaries = summaries.slice(6);
      const csq0 = summary[0];
      const csq1 = summary[1];
      const csq2 = summary[2];
      const csq3 = summary[3];
      const csq4 = summary[4];
      const csq5 = summary[5];
      csq.set(uptime, { csq0, csq1, csq2, csq3, csq4, csq5 });
      uptime -= summaryPeriod;
    }
    setSpider((spider) => ({
      ...spider,
      csq: Object.assign(spider.csq ?? {}, Object.fromEntries(csq)),
      satelliteNetworkSignalQuality: satelliteNetwork,
    }));
  }
}

export function decodeStream(chunk, setSpider) {
  if (chunk.length < 6) {
    console.error('stream too short');
    return;
  }
  const id = chunk[0] + (chunk[1] << 8);
  const crc =
    (chunk[chunk.length - 4] +
      (chunk[chunk.length - 3] << 8) +
      (chunk[chunk.length - 2] << 16) +
      (chunk[chunk.length - 1] << 24)) >>>
    0;
  const crcCheck = crc32(chunk.slice(0, chunk.length - 4)) >>> 0;
  if (crc !== crcCheck) {
    console.error('stream crc mismatch');
    return;
  }
  const packet = chunk.slice(2, chunk.length - 4);

  const bufferReader = new BufferReader(packet);
  const payloadHeaderLength = bufferReader.uint32() + bufferReader.pos;

  const protobufHeader = ProtobufEventHeader.decodeDelimited(packet);

  if (protobufHeader.payloadLength + payloadHeaderLength !== packet.length) {
    console.error('stream payload too short');
    return;
  }
  const payload = packet.subarray(payloadHeaderLength);
  const eventType = protobufHeader.eventType;

  switch (eventType) {
    case ProtobufEventHeader.EventType.kPowerGetResponse:
    case ProtobufEventHeader.EventType.kPowerUpdate:
      decodePower(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kOperatingModeGetResponse:
    case ProtobufEventHeader.EventType.kOperatingModeUpdate:
      decodeOperatingMode(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kSnifferData:
      decodeSniffer(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kSettingsGetResponseMain:
      decodeSetingsMain(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kSettingsGetResponseKeypad:
      decodeSettingsKeypad(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kSettingsGetResponseGpi:
      decodeSettingsGpi(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kExternalDeviceStateGetResponse:
    case ProtobufEventHeader.EventType.kExternalDeviceStateUpdate:
      decodeExternalDevice(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kPeripheralHostGetResponse:
    case ProtobufEventHeader.EventType.kPeripheralSlaveGetResponse:
    case ProtobufEventHeader.EventType.kPeripheralHostConnected:
    case ProtobufEventHeader.EventType.kPeripheralHostDisconnected:
      decodePeripheral(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kPoint:
      decodePoint(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kGpiGetResponse:
    case ProtobufEventHeader.EventType.kGpiUpdate:
      decodeGpi(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kInertialNavigationSystem:
      decodeIns(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kNavigationDiagnosticGetResponse:
      decodeNavigationDiagnostic(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kInertialNavigationDiagnostics:
      decodeInsDiagnostics(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kDataBlockDiagnosticGetResponse:
      decodeBlockDiagnostics(payload, setSpider);
      break;
    case ProtobufEventHeader.EventType.kSatelliteNetworkDiagnosticGetResponse:
      decodeSatDiagnostics(payload, setSpider);
      break;
  }
}
