import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Provider as CredentialsProvider } from './context/CredentialsContext';
import { Provider as FirmwareProvider } from './context/FirmwareContext';
import { Provider as SXProvider } from './context/SpiderXContext';
import { Provider as BLEProvider } from './context/BLEContext';
import { Provider as UpdateProvider } from './context/UpdateContext';
import { Provider as SpiderIdentificationProvider } from './context/SpiderIdentificationContext';

import Update from './components/Update';
import Status from './components/Status';

const Routes = () => (
  <Switch>
    <Route path="/update/:channel?">
      <SpiderIdentificationProvider>
        <SXProvider>
          <FirmwareProvider>
            <CredentialsProvider>
              <UpdateProvider>
                <Update />
              </UpdateProvider>
            </CredentialsProvider>
          </FirmwareProvider>
        </SXProvider>
      </SpiderIdentificationProvider>
    </Route>
    <Route path="/status">
      <BLEProvider>
        <Status />
      </BLEProvider>
    </Route>
    <Route path="*" exact render={() => <Redirect to="/update" />} />
  </Switch>
);

export default Routes;
