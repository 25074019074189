import React, { createContext } from 'react';
import { useParams } from 'react-router-dom';

import { useFirmwaresForContext } from '../hooks/useFirmwares';
import PropTypes from 'prop-types';

export const FirmwareContext = createContext();

export const Provider = ({ children }) => {
  const { channel } = useParams();

  const firmwares = useFirmwaresForContext(channel || 'stable');

  return <FirmwareContext.Provider value={firmwares}>{children}</FirmwareContext.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};
