import { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import * as config from '../api/aws-exports';

const useEnv = () => {
  let stage;
  switch (window.location.hostname) {
    case 'sx.update.dev.spidertracks.io':
      stage = 'dev';
      break;
    case 'sx.update.test.spidertracks.io':
      stage = 'test';
      break;
    case 'sx.update.spidertracks.io':
      stage = 'prod';
      break;
    default:
      stage = 'dev';
      break;
  }

  const env = stage;

  useEffect(() => {
    Amplify.configure(config[env]);
  }, [env]);

  return [env];
};

export default useEnv;
