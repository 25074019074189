import React, { createContext } from 'react';
import { useSpiderIdentificationForContext } from '../hooks/useSpiderIdentification';
import PropTypes from 'prop-types';

export const SpiderIdentificationContext = createContext(null);

export const Provider = ({ children }) => {
  const identification = useSpiderIdentificationForContext();
  return (
    <SpiderIdentificationContext.Provider value={identification}>
      {children}
    </SpiderIdentificationContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};
