import { useEffect, useState, useContext } from 'react';
import { SpiderIdentificationContext } from '../context/SpiderIdentificationContext';

const useSpiderIdentificationForContext = () => {
  const [spiderSerial, setSpiderSerial] = useState('');
  const [spiderPin, setSpiderPin] = useState('');
  const [spiderIdentificationValid, setSpiderIdentificationValid] = useState(false);
  const [requiresNewCredentials, setRequiresNewCredentials] = useState(false);

  useEffect(() => {
    setSpiderIdentificationValid(spiderSerial.length === 10 && spiderPin.length === 4);
  }, [spiderSerial, spiderPin]);

  return [
    spiderSerial,
    spiderPin,
    setSpiderSerial,
    setSpiderPin,
    spiderIdentificationValid,
    requiresNewCredentials,
    setRequiresNewCredentials,
  ];
};

const useSpiderIdentification = () => {
  const v = useContext(SpiderIdentificationContext);

  return v;
};
export { useSpiderIdentificationForContext };
export default useSpiderIdentification;
