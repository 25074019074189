import React, { useState, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import deviceLib from '../lib/device';
import sxCommands from '../lib/sxCommands';

export const SpiderXContext = createContext();

const SXVendorId = 1155;

const useProvideSpiderX = () => {
  const [device, setDevice] = useState();
  const [serial, setSerial] = useState();
  const [isDfu, setIsDfu] = useState(false);
  const [uploadProgress, setProgress] = useState();

  function promiseForEvent(eventTarget, eventType) {
    return new Promise((resolve) => {
      let eventHandler = (evt) => {
        resolve(evt);
        eventTarget.removeEventListener(eventTarget, eventHandler);
      };
      eventTarget.addEventListener(eventType, eventHandler);
    });
  }

  const connectUsb = () => {
    promiseForEvent(navigator.usb, 'disconnect').then(() => {
      console.log('usb disconnected');
      setIsDfu(false);
      setDevice(undefined);
    });

    if (device != undefined) return Promise.resolve();

    return navigator.usb
      .getDevices()
      .then((d) => {
        if (d.length > 0) {
          return d.find((dev) => dev.vendorId === SXVendorId);
        } else {
          return navigator.usb.requestDevice({ filters: [{ vendorId: SXVendorId }] });
        }
      })
      .then((d) => {
        const myDevice = { device_: d };
        if (myDevice.device_.productName.indexOf('DFU') >= 0) {
          console.log('DFU mode');
          setIsDfu(true);
          return deviceLib.connect(d);
        } else {
          return myDevice;
        }
      })
      .then((d) => {
        if (d.device_.productName.indexOf('DFU') >= 0) {
          d.logProgress = (progress, done) => {
            let percent;
            if (progress === 0) {
              percent = 0;
            } else {
              percent = (progress / done) * 100;
            }

            //console.log(`${progress}/${done} (${percent})%`);

            setProgress(percent);
          };
        }

        console.log('Setting device', d.device_);
        setDevice(d);
      });
  };

  const connectSerial = () => {
    promiseForEvent(navigator.serial, 'disconnect').then(() => {
      console.log('serial disconnected');
      setSerial(undefined);
    });

    if (serial != undefined) return Promise.resolve();

    let mySerial;
    return navigator.serial
      .getPorts()
      .then((ports) => {
        if (ports.length > 0) {
          return ports.find((s) => s.getInfo()['usbVendorId'] === SXVendorId);
        } else {
          return navigator.serial.requestPort({ filters: [{ usbVendorId: SXVendorId }] });
        }
      })
      .then((s) => {
        mySerial = s;
        return s.open({ baudRate: 115200 });
      })
      .then(() => {
        console.log('Setting serial', mySerial);
        setSerial(mySerial);
      });
  };

  const disconnect = async () => {
    console.log('disconnecting device');

    try {
      if (device != undefined) {
        await device.device_.close();
      }
      if (serial != undefined) {
        await serial.close();
      }
    } catch (e) {
      console.log('disconnect error: ', e);
    }
    setDevice(undefined);
    setSerial(undefined);
    setIsDfu(false);
    return Promise.resolve();
  };

  const getRxStream = () => {
    const readableStream = new ReadableStream({
      start(controller) {
        device.device_.controlTransferOut({
          requestType: 'class',
          recipient: 'interface',
          request: 0x22,
          value: 0x01,
          index: 0x01,
        });
      },
      pull(controller) {
        return device.device_
          .transferIn(1, 64)
          .then(({ data }) => {
            return controller.enqueue(data);
          })
          .catch((e) => {
            console.error('Problem pulling form device', e);
            return Promise.reject(e);
          });
      },
    });

    var decoder = new TextDecoderStream();
    return readableStream.pipeThrough(decoder);
  };

  const connectedUsb = useMemo(() => !!device, [device]);
  const connectedSerial = useMemo(() => !!serial, [serial]);

  const pushImage = async (start_address, firmware, restart, erase, erase_all = false) => {
    // Attempt to parse the DFU functional descriptor
    console.log('pushImage', start_address, firmware, restart);
    console.log('Device Description', device.properties);

    device.startAddress = start_address;
    return device
      .do_download(device.properties.TransferSize, firmware, restart, erase, erase_all)
      .then(
        () => {
          console.log('Done');
        },
        (error) => {
          console.error('pushImage error', error);
          return Promise.reject(error);
        }
      );
  };

  const commands = () => sxCommands(serial);

  return {
    isDfu,
    device,
    serial,
    connectUsb,
    connectSerial,
    disconnect,
    connectedUsb,
    connectedSerial,
    getRxStream,
    pushImage,
    uploadProgress,
    commands,
  };
};

export const Provider = ({ children }) => {
  const spiderx = useProvideSpiderX();
  return <SpiderXContext.Provider value={spiderx}>{children}</SpiderXContext.Provider>;
};
Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Consumer = SpiderXContext.Consumer;
