import { Auth } from 'aws-amplify';
import { Lambda } from 'aws-sdk';
import { useContext } from 'react';
import useEnv from './useEnv';
import useAuth from './useAuth';
import useSpiderIdentification from './useSpiderIdentification';
import { CredentialsContext } from '../context/CredentialsContext';
import struct from 'python-struct';
import { Buffer } from 'buffer';
import useSpiderX from './useSpiderX';

const useCredentialsForContext = () => {
  const [env] = useEnv();
  const auth = useAuth();
  const spider = useSpiderX();
  const [spiderSerial, spiderPin, , , spiderIdentificationValid] = useSpiderIdentification();

  const reprovision = async (on_creds_fetched, manifest = false) => {
    if (env && auth.user && spiderIdentificationValid) {
      return Auth.currentCredentials()
        .then((credentials) => {
          if (!credentials.authenticated) {
            return Promise.reject('Not authenticated');
          }
          const lambda = new Lambda({
            apiVersion: '2015-03-31',
            region: 'us-west-2',
            credentials: Auth.essentialCredentials(credentials),
          });
          console.log(
            'Payload:',
            JSON.stringify({
              body: JSON.stringify({
                serialNumber: spiderSerial,
                PIN: spiderPin,
              }),
              queryStringParameters: {
                activate: true,
              },
            })
          );
          return lambda
            .invoke({
              FunctionName: `credentials-post-${env}`,
              Payload: JSON.stringify({
                body: JSON.stringify({
                  serialNumber: spiderSerial,
                  PIN: spiderPin,
                }),
                queryStringParameters: {
                  activate: 'true',
                },
              }),
            })
            .promise()
            .then((a) => {
              let payload = JSON.parse(a.Payload);
              if (payload.statusCode < 200 || payload.statusCode >= 300) {
                return Promise.reject(payload.body || payload.statusCode);
              }
              return JSON.parse(payload.body);
            })
            .then(async (response) => {
              const extract_as_der = (pem, header, footer) => {
                const key = pem.slice(header.length, pem.length - footer.length);
                const buff = Buffer.alloc(2048);
                const bytesWritten = buff.write(key, 'base64');
                return [buff, bytesWritten];
              };

              const device_cert = extract_as_der(
                response.IotCertificate,
                '-----BEGIN CERTIFICATE-----',
                '-----END CERTIFICATE-----'
              );
              const device_key = extract_as_der(
                response.PrivateKey,
                '-----BEGIN RSA PRIVATE KEY-----',
                '-----END RSA PRIVATE KEY-----'
              );

              const descriptor_format = 'IIIIIII68b';
              const descriptor_size = 96;
              const credentials_size = 4160;
              const sequence = parseInt(new Date().getTime() / 1000);
              const start_addr = 0;
              const end_addr = descriptor_size + credentials_size;
              const exec_addr = descriptor_size;
              const hardware_id = 1;
              const version = 0x05000000;
              let environment = 0;
              if (env === 'test') {
                environment = 1;
              } else if (env === 'dev') {
                environment = 2;
              }

              const descriptor = struct.pack(descriptor_format, [
                sequence,
                start_addr,
                end_addr,
                exec_addr,
                hardware_id,
                version,
                environment,
                ...Buffer.alloc(68, 0xff), // Descriptor padding
              ]);

              const pcks11_format = '96b2048bII24x2048bII24x';
              const pcks11_magic = 0xabcd0000;

              const credentials = struct.pack(pcks11_format, [
                ...descriptor,
                ...device_cert[0],
                pcks11_magic,
                device_cert[1],
                ...device_key[0],
                pcks11_magic,
                device_key[1],
              ]);

              const signature = new Uint8Array(
                await window.crypto.subtle.digest('SHA-256', credentials)
              );

              const image_format = '32s32b32bI28b32b256b32sI32b252b3360b4256b';
              const image_magic = '@VER002';
              const sig_type = 'sig-sha256';

              return struct.pack(image_format, [
                image_magic,
                ...Buffer.alloc(32, 0xff), // OTA flag
                ...Buffer.alloc(32, 0xff), // Already-loaded flag
                0, // Valid flag
                ...Buffer.alloc(28, 0xff), // Valid flag padding
                ...Buffer.alloc(32, 0xff), // Invalid flag
                ...Buffer.alloc(256, 0xff), // Header padding
                sig_type,
                32, // Signature length
                ...signature,
                ...Buffer.alloc(252, 0xff), // Tail padding
                ...Buffer.alloc(3360, 0xff), // Metadata padding
                ...credentials,
              ]);
            })
            .then((image) => {
              if (on_creds_fetched) {
                on_creds_fetched();
              }
              return spider.pushImage(0x081e0000, image, manifest, true, true);
            });
        })
        .catch((err) => {
          console.log('Error during reprovisioning occured', err);
          return Promise.reject(err);
        });
    } else {
      return Promise.reject('Not logged in');
    }
  };

  return {
    reprovision,
  };
};

const useCredentials = () => {
  const v = useContext(CredentialsContext);
  return v;
};
export { useCredentialsForContext };
export default useCredentials;
