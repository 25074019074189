/* eslint-env node */
module.exports.dev = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_LS69Erdyt',
    userPoolWebClientId: 'jr04no0hbbb11rlfaiku299up',
    authenticationFlowType: 'CUSTOM_AUTH',
    identityPoolId: 'us-west-2:abc0ac1e-121a-4b61-b06b-4848d981ad92',
  },
};

module.exports.test = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_pL71vGYB7',
    userPoolWebClientId: '17r79us2hv4gkrttmf7k6hd13v',
    authenticationFlowType: 'CUSTOM_AUTH',
    identityPoolId: 'us-west-2:44e02e6b-1452-4b27-ba04-299e2ee961b8',
  },
};

module.exports.prod = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_Vp0BmLqw1',
    userPoolWebClientId: '1a745oi1bkj71d7chgpjqiadvl',
    authenticationFlowType: 'CUSTOM_AUTH',
    identityPoolId: 'us-west-2:22c3247d-6ce3-4960-85d0-f9cfb6c74e40',
  },
};
