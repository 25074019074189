import React, { useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import PropTypes from 'prop-types';

export const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);

  const retrieveSetUser = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => setUser(user))
      .catch(() => setUser(null));
  };

  // fetch the info of the user that may be already logged in
  useEffect(() => {
    retrieveSetUser();

    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          setUser(data.payload.data);
          break;
        case 'signOut':
          setUser(null);
          break;
      }
    });
  }, []);

  // make sure other components can read this value
  return <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
