import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import AuthenticatorWrap from './components/AuthenticatorWrap';
import Routes from './Routes';
import './App.css';
import { AuthProvider } from './context/AuthContext';
import useEnv from './hooks/useEnv';
import generatedGitInfo from './generatedGitInfo.json';

const { Footer, Content, Header } = Layout;

function App() {
  useEnv();
  return (
    <BrowserRouter>
      <AuthProvider>
        <Layout className="App-layout">
          <Header
            className="App-header"
            title={'SX Updater Version: ' + generatedGitInfo.gitCommitHash}
          >
            <img
              className="Spidertracks-logo"
              src={process.env.PUBLIC_URL + '/Spidertracks-full-v2.svg'}
            />
          </Header>
          <Content className="App-content">
            <AuthenticatorWrap>
              <Routes />
            </AuthenticatorWrap>
          </Content>
        </Layout>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
