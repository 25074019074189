import Auth from '@aws-amplify/auth';
import { Alert, Button, Space, Card, Form, Input, PageHeader, Progress, Typography } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';

import useAuth from '../../hooks/useAuth';
import useCredentials from '../../hooks/useCredentials';
import useEnv from '../../hooks/useEnv';
import useFirmwares from '../../hooks/useFirmwares';
import useSpiderIdentification from '../../hooks/useSpiderIdentification';
import useSpiderX from '../../hooks/useSpiderX';
import useUpdate from '../../hooks/useUpdate';

const layout = {
  layout: 'vertical',
};
const tailLayout = {
  wrapperCol: {
    span: 16,
  },
};

const state_transitions = {
  connect_usb: 'wait_serial',
  wait_serial: 'connect_serial',
  connect_serial: 'connect_dfu',
  connect_dfu: 'wait_program',
  wait_program: 'cert',
  cert: 'reprovision',
  reprovision: 'program_coprocessor',
  program_coprocessor: 'program_bootloader',
  program_bootloader: 'start_bootloader',
  start_bootloader: 'reconnect_dfu',
  reconnect_dfu: 'program_main',
  program_main: 'update_status',
  update_status: '',
};

const task_state = {
  done: false,
  required: true,
  progress: NaN,
  task_started: false,
};
const initialState = () => ({
  current: '',
  progress: 0,
  in_progress: false,
  connected: false,
  serial_valid: false,
  serial_error: false,
  error_message: undefined,
  info_message: undefined,
  tasks: {
    connect_usb: {
      ...task_state,
    },
    wait_serial: {
      ...task_state,
    },
    connect_serial: {
      ...task_state,
    },
    connect_dfu: {
      ...task_state,
    },
    wait_program: {
      ...task_state,
    },
    cert: {
      ...task_state,
      progress: 5,
      previous_progress: 0,
    },
    reprovision: {
      ...task_state,
      progress: 47,
      previous_progress: 5,
    },
    program_coprocessor: {
      ...task_state,
      progress: 13,
      previous_progress: 52,
    },
    program_bootloader: {
      ...task_state,
      progress: 8,
      previous_progress: 65,
    },
    start_bootloader: {
      ...task_state,
      progress: 2,
      previous_progress: 73,
    },
    reconnect_dfu: {
      ...task_state,
      progress: 0,
      previous_progress: 75,
    },
    program_main: {
      ...task_state,
      progress: 24,
      previous_progress: 75,
    },
    update_status: {
      ...task_state,
      progress: 1,
      previous_progress: 99,
    },
  },
});

function taskComplete(task) {
  return !task.required || (task.required && task.done);
}

function reducer(state, action) {
  console.log(state, action);
  let find_next_state = (current_task, current_state) => {
    if (current_task === '') {
      return '';
    }

    if (taskComplete(current_state.tasks[current_task])) {
      return find_next_state(state_transitions[current_task], current_state);
    }
    return current_task;
  };

  if (action.type === 'start') {
    return {
      ...state,
      connected: false,
      current: find_next_state('connect_usb', state),
    };
  }

  if (action.type === 'reset') {
    return initialState(true);
  }

  if (action.type === 'in_progress') {
    return {
      ...state,
      in_progress: true,
    };
  }

  if (action.type === 'connected') {
    return {
      ...state,
      connected: true,
    };
  }

  if (action.type === 'error') {
    return {
      ...state,
      error_message: action.message,
    };
  }

  if (action.type === 'info') {
    return {
      ...state,
      info_message: action.message,
    };
  }

  if (action.type === 'serial_valid') {
    return {
      ...state,
      serial_valid: action.valid,
      serial_error: action.error,
    };
  }

  if (action.type === 'progress') {
    let state_name = 'task' in action ? action.task : state.current;
    if (state_name != '') {
      const current_task = state.tasks[state_name];
      let next_state = {
        ...state,
        tasks: {
          ...state.tasks,
          [state_name]: {
            ...current_task,
            done: action.progress == 100,
          },
        },
      };
      const final_state = {
        ...next_state,
        progress: Math.round(
          current_task.previous_progress + (current_task.progress * action.progress) / 100.0
        ),
        current: find_next_state(next_state.current, next_state),
      };
      return final_state;
    } else {
      return {
        ...state,
        in_progress: false,
      };
    }
  }

  if (action.type === 'task_started' && state.current != '') {
    const current_task = state.tasks[state.current];
    let next_state = {
      ...state,
      tasks: {
        ...state.tasks,
        [state.current]: {
          ...current_task,
          task_started: true,
        },
      },
    };
    const final_state = {
      ...next_state,
      current: find_next_state(next_state.current, next_state),
    };
    return final_state;
  }

  const next_state = {
    ...state,
    tasks: {
      ...state.tasks,
      [action.type]: {
        ...state.tasks[action.type],
        done: action.done,
      },
    },
  };
  const final_state = {
    ...next_state,
    current: find_next_state(next_state.current, next_state),
  };
  return final_state;
}

const Update = () => {
  const [env] = useEnv();
  const spiderx = useSpiderX();
  const credentials = useCredentials();
  const update = useUpdate();
  const [userPresent, setUserPresent] = useState(true);
  const [signingOut, setSigningOut] = useState(false);
  const [, , setSpiderSerial, setSpiderPin] = useSpiderIdentification();
  const [
    mainFirmware,
    mainFetched,
    bootloaderFirmware,
    bootloaderFetched,
    coprocessorFirmware,
    coprocessorFetched,
    mainFetchFailed,
    bootloaderFetchFailed,
    coprocessorFetchFailed,
    mainVersionString,
    bootloaderVersionString,
    coprocessorVersionString,
  ] = useFirmwares();
  const [state, dispatch] = useReducer(reducer, env, initialState);
  const [form] = Form.useForm();
  const auth = useAuth();

  const [mainImage, setMainImage] = useState();
  const [bootloaderImage, setBootloaderImage] = useState();
  const [coprocessorImage, setCoprocessorImage] = useState();

  const onFinish = (values) => {
    setSpiderSerial(values.serial.split('-')[0].toUpperCase());
    setSpiderPin(values.serial.split('-')[1]);
    dispatch({ type: 'start' });
  };

  const logOut = async () => {
    try {
      setSigningOut(true);
      await Auth.signOut();
      window.location.reload();
    } catch (e) {
      console.log('error signing out: ', e);
    }
  };

  const restart = () => {
    const serial_valid = state.serial_valid;
    const serial_error = state.serial_error;
    dispatch({ type: 'reset' });
    dispatch({ type: 'serial_valid', error: serial_error, valid: serial_valid });
    spiderx.disconnect();
  };

  useEffect(() => {
    if (mainFirmware) {
      const data = new Uint8Array(mainFirmware);
      const offset = 0;
      const magic = new TextDecoder().decode(data.slice(offset, offset + 7));
      if (magic === '@VER002') {
        for (let i = 0; i < 32; i++) {
          data[offset + 32 + i] = 0xff;
        }
      }
      setMainImage(data.buffer);
    }
  }, [mainFirmware]);

  useEffect(() => {
    if (bootloaderFirmware) {
      const data = new Uint8Array(bootloaderFirmware);
      const offset = 2 * 128 * 1024 - 4096;
      const magic = new TextDecoder().decode(data.slice(offset, offset + 7));
      if (magic === '@VER002') {
        for (let i = 0; i < 32; i++) {
          data[offset + 32 + i] = 0xff;
        }
      }
      setBootloaderImage(data.buffer);
    }
  }, [bootloaderFirmware]);

  useEffect(() => {
    if (coprocessorFirmware) {
      const data = new Uint8Array(coprocessorFirmware);
      const offset = 512 * 1024 - 4096;
      const magic = new TextDecoder().decode(data.slice(offset, offset + 7));
      if (magic === '@VER002') {
        for (let i = 0; i < 32; i++) {
          data[offset + 32 + i] = 0xff;
        }
      }
      setCoprocessorImage(data.buffer);
    }
  }, [coprocessorFirmware]);

  useEffect(() => {
    if (Auth.user === null) {
      // Detect user logged out and reload page to show sign in screen
      window.location.reload();
    }
  }, [Auth.user]);

  useEffect(() => {
    dispatch({ type: 'progress', progress: spiderx.uploadProgress });
  }, [state.tasks.current, spiderx.uploadProgress]);

  const later = (delay, value) => new Promise((resolve) => setTimeout(resolve, delay, value));

  // Look for Spider X as USB device
  useEffect(() => {
    if (state.current === 'connect_usb') {
      console.log('Requesting USB');
      spiderx.connectUsb().catch((e) => {
        console.log(e.message);
        dispatch({
          type: 'error',
          message: 'Unable to connect to Spider X USB port',
        });
      });
    }
  }, [state.current]);

  // Detect USB mode
  useEffect(() => {
    if (state.current === 'connect_usb' && spiderx.connectedUsb && !spiderx.isDfu) {
      dispatch({ type: 'connect_usb', done: true });
    }
    if (state.current === 'start_bootloader' && !spiderx.isDfu) {
      later(12000).then(() => dispatch({ type: 'start_bootloader', done: true }));
    }
  }, [state.current, spiderx.connectedUsb, spiderx.isDfu]);

  // Prompt to open serial
  useEffect(() => {
    if (
      state.current === 'wait_serial' ||
      state.current === 'wait_program' ||
      state.current === 'cert' ||
      state.current === 'reprovision' ||
      state.current === 'program_coprocessor' ||
      state.current === 'program_bootloader' ||
      state.current === 'program_main'
    ) {
      if (!spiderx.connectedUsb) {
        dispatch({
          type: 'info',
          message: 'Lost contact with Spider X',
        });
      }
    }
  }, [state.current, spiderx.connectedUsb]);

  // Look for Spider X as serial port
  useEffect(() => {
    if (state.current === 'connect_serial') {
      console.log('Requesting Serial');
      spiderx.connectSerial().catch((e) => {
        console.log(e.message);
        dispatch({
          type: 'info',
          message: 'Unable to connect to Spider X serial port. Close any existing connections',
        });
      });
    }
  }, [state.current]);

  // Send serial command to boot into DFU
  useEffect(() => {
    if (state.current === 'connect_serial') {
      if (spiderx.connectedSerial) {
        console.log('Requesting DFU');
        spiderx
          .commands()
          .clearCommands()
          .then(() => {
            return later(100);
          })
          .then(() => {
            return spiderx.commands().rebootIntoBootloader();
          })
          .then(() => {
            dispatch({ type: 'connect_serial', done: true });
          })
          .catch((e) => {
            console.log(e.message);
            dispatch({
              type: 'error',
              message: 'Unable to switch Spider X into DFU mode',
            });
          });
      }
    }
  }, [state.current, spiderx.connectedSerial]);

  // Look for Spider X in DFU mode
  useEffect(() => {
    if (
      (state.current === 'connect_dfu' || state.current === 'reconnect_dfu') &&
      !spiderx.connectedSerial &&
      !spiderx.connectedUsb
    ) {
      if (!navigator.userActivation.isActive) {
        setUserPresent(false);
        return;
      }
      console.log('Requesting DFU');
      spiderx.connectUsb().catch((e) => {
        console.log(e.message);
        dispatch({
          type: 'error',
          message: 'Unable to connect to Spider X in DFU mode',
        });
      });
    }
  }, [state.current, spiderx.connectedSerial, spiderx.connectedUsb, userPresent]);

  // Detect DFU Mode
  useEffect(() => {
    if (
      (state.current === 'connect_usb' ||
        state.current === 'wait_serial' ||
        state.current === 'connect_serial' ||
        state.current === 'connect_dfu') &&
      spiderx.connectedUsb &&
      spiderx.isDfu
    ) {
      dispatch({ type: 'connect_usb', done: true });
      dispatch({ type: 'wait_serial', done: true });
      dispatch({ type: 'connect_serial', done: true });
      dispatch({ type: 'connect_dfu', done: true });
      dispatch({ type: 'connected' });
    }
    if (state.current === 'reconnect_dfu' && spiderx.connectedUsb && spiderx.isDfu) {
      dispatch({ type: 'reconnect_dfu', done: true });
    }
  }, [state.current, spiderx.connectedUsb, spiderx.isDfu]);

  const can_transition = (previous_step, step_name) => {
    return (
      state.current == step_name &&
      spiderx.connectedUsb &&
      spiderx.isDfu &&
      taskComplete(state.tasks[previous_step]) &&
      !taskComplete(state.tasks[step_name]) &&
      !state.tasks[step_name].task_started
    );
  };

  useEffect(() => {
    if (can_transition('wait_program', 'cert')) {
      console.log('Provisioning certificates');
      dispatch({ type: 'task_started' });
      credentials
        .reprovision(() => {
          dispatch({ type: 'cert', done: true });
        }, false)
        .catch((err) => {
          dispatch({
            type: 'error',
            message: 'Error provisioning certificates (' + err + ')',
          });
        });
    }
  }, [
    spiderx.connectedUsb,
    spiderx.connectedSerial,
    spiderx.isDfu,
    state.current,
    state.tasks.wait_program,
    state.tasks.cert,
  ]);

  useEffect(() => {
    if (can_transition('reprovision', 'program_coprocessor')) {
      console.log('Programming coprocessor', coprocessorFirmware, coprocessorFirmware.byteLength);
      dispatch({ type: 'task_started' });
      later(1000)
        .then(() => spiderx.pushImage(0x08160000, coprocessorFirmware, false, false))
        .catch(() => {
          dispatch({
            type: 'error',
            message: 'Error programming coprocessor',
          });
        });
    }
  }, [
    spiderx.connectedUsb,
    spiderx.connectedSerial,
    spiderx.isDfu,
    state.current,
    state.tasks.reprovision,
    state.tasks.program_coprocessor,
  ]);

  useEffect(() => {
    if (can_transition('program_coprocessor', 'program_bootloader')) {
      console.log('Programming bootloader', bootloaderFirmware, bootloaderFirmware.byteLength);
      dispatch({ type: 'task_started' });
      later(1000)
        .then(() => spiderx.pushImage(0x08000000, bootloaderFirmware, true, false))
        .catch(() => {
          dispatch({
            type: 'error',
            message: 'Error programming bootloader',
          });
        });
    }
  }, [
    spiderx.connectedUsb,
    spiderx.connectedSerial,
    spiderx.isDfu,
    state.current,
    state.tasks.program_coprocessor,
    state.tasks.program_bootloader,
  ]);

  useEffect(() => {
    if (can_transition('reconnect_dfu', 'program_main')) {
      console.log('Programming main', mainFirmware, mainFirmware.byteLength);
      dispatch({ type: 'task_started' });
      later(1000)
        .then(() => spiderx.pushImage(0x08040000, mainFirmware, true, false))
        .catch(() => {
          dispatch({
            type: 'error',
            message: 'Error programming main',
          });
        });
    }
  }, [
    spiderx.connectedUsb,
    spiderx.connectedSerial,
    spiderx.isDfu,
    state.current,
    state.tasks.reconect_dfu,
    state.tasks.program_main,
  ]);

  useEffect(() => {
    if (
      state.current == 'update_status' &&
      taskComplete(state.tasks.program_main) &&
      !taskComplete(state.tasks.update_status) &&
      !state.tasks.update_status.task_started
    ) {
      console.log('Updating status');
      dispatch({ type: 'task_started' });
      update
        .sync(mainVersionString, bootloaderVersionString, coprocessorVersionString)
        .then(() => dispatch({ type: 'progress', progress: 100 }))
        .catch((err) => {
          dispatch({
            type: 'error',
            message: 'Error sending status (' + err + ')',
          });
        });
    }
  }, [state.current, state.tasks.program_main, state.tasks.update_status]);

  useEffect(() => {
    if (auth.user === null) {
      setSigningOut(true);
      window.location.reload();
    }
  }, [auth.user]);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const resetPage = (event) => {
    event.preventDefault();
    dispatch({ type: 'reset' });
    spiderx.disconnect();
    form.resetFields();
  };

  const connectSerial = () => {
    if (state.current === 'wait_serial') {
      dispatch({ type: 'wait_serial', done: true });
    }
  };

  const startProgram = () => {
    if (state.current === 'wait_program') {
      dispatch({ type: 'wait_program', done: true });
      dispatch({ type: 'in_progress' });
    }
  };

  const userHere = () => {
    setUserPresent(true);
  };

  const download =
    mainFetchFailed !== undefined ? (
      <Alert message={mainFetchFailed} type="error" showIcon />
    ) : bootloaderFetchFailed !== undefined ? (
      <Alert message={bootloaderFetchFailed} type="error" showIcon />
    ) : coprocessorFetchFailed !== undefined ? (
      <Alert message={coprocessorFetchFailed} type="error" showIcon />
    ) : !mainFetched || !bootloaderFetched || !coprocessorFetched ? (
      <Alert message="Downloading firmware..." type="warning" showIcon />
    ) : null;

  const signout = signingOut ? <Alert message="Signing out..." type="warning" showIcon /> : null;

  const progress =
    state.error_message == undefined && state.in_progress && state.progress !== 100 ? (
      <Card>
        <Space direction="vertical">
          <Alert
            message="Spider X is updating - do not disconnect or navigate to another page"
            type="info"
            showIcon
          />
          <Progress percent={state.progress} status="active" />
          <Button type="primary" htmlType="submit" onClick={userHere} danger disabled={userPresent}>
            Click to Complete Update
          </Button>
        </Space>
      </Card>
    ) : null;

  const retry =
    state.progress != 100 ? (
      <Button type="primary" onClick={restart}>
        Retry
      </Button>
    ) : (
      <Button type="primary" onClick={resetPage}>
        Done
      </Button>
    );

  const contact_help = (
    <>
      Contact <a href="mailto:support@spidertracks.com">support@spidertracks.com</a> for help.
    </>
  );

  const driver_error = (
    <>
      On Windows machines please download and install the {}
      <a className="Driver-link" href={process.env.PUBLIC_URL + '/spider_x_bootloader_1.1.0.exe'}>
        Spider X bootloader driver
      </a>
      {} before retrying.
      <p></p>
      {contact_help}
    </>
  );

  const connect_error =
    state.error_message != undefined && !state.connected ? (
      <Alert
        message={state.error_message}
        type="error"
        showIcon
        description={driver_error}
        action={retry}
      />
    ) : null;

  const status =
    state.error_message != undefined && state.connected ? (
      <Alert
        message={state.error_message}
        type="error"
        showIcon
        description={contact_help}
        action={retry}
      />
    ) : state.in_progress && state.progress == 100 ? (
      <Alert
        message={'Spider X updated to version ' + mainVersionString}
        type="success"
        showIcon
        description="Please leave Spider plugged in for 2 minutes to finish update"
        action={
          <Button type="primary" onClick={resetPage}>
            Done
          </Button>
        }
      />
    ) : null;

  const validate_serial = (e) => {
    const { value } = e.target;
    const reg = /(^[A-Za-z0-9]{0,10}$)|(^[A-Za-z0-9]{10}-$)|(^[A-Za-z0-9]{10}-\d{0,4}$)/;
    const err = !reg.test(value);
    dispatch({ type: 'serial_valid', error: err, valid: !err && value.length == 15 });
  };

  const error_text = state.serial_error ? (
    <Typography.Text type="danger">
      Please input your serial number in the form ABCDEFGHIJ-1234
    </Typography.Text>
  ) : null;

  const update_info =
    mainVersionString !== undefined && mainImage && bootloaderImage && coprocessorImage ? (
      <>
        <br></br>
        Firmware version {mainVersionString} will be applied to your Spider X. Release notes can be
        found {}
        <a href="https://spidertrackshelp.zendesk.com/hc/en-nz/articles/8608929958287-Spider-X-Firmware-Version-History">
          here
        </a>
        .
      </>
    ) : null;

  const connect_warning =
    state.current === 'connect_dfu' && state.error_message === undefined ? (
      <Alert message="Attempting to contact Spider..." type="warning" showIcon />
    ) : null;

  const connect_info =
    state.info_message != undefined && state.error_message === undefined ? (
      <Alert message={state.info_message} type="info" showIcon action={retry} />
    ) : state.current === 'wait_serial' && spiderx.connectedUsb ? (
      <Alert
        message="Spider connected via USB"
        type="info"
        showIcon
        action={
          <Button type="primary" onClick={connectSerial}>
            Open Serial Port
          </Button>
        }
      />
    ) : null;

  return (
    <PageHeader ghost={false} title="Update Spider X">
      <Form
        {...layout}
        form={form}
        name="update-device"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <p>
          Instructions for using this tool can be found {}
          <a href="https://spidertrackshelp.zendesk.com/hc/en-nz/articles/8608835648911-How-To-update-Spider-X-Firmware">
            here
          </a>
          .
        </p>

        <Card title="Step 1">
          <Form.Item
            label="Enter Serial Number and Pin - located on the bottom of the device."
            name="serial"
            onChange={validate_serial}
          >
            <Input
              disabled={signingOut || state.current != '' || state.error_message != undefined}
              placeholder="e.g. ABCDEFGHIJ-1234"
            />
          </Form.Item>
          {error_text}
          {download}
        </Card>
        <Card title="Step 2">
          <p>Connect Spider X to computer and click the button below.</p>
          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                signingOut ||
                !mainImage ||
                !bootloaderImage ||
                !coprocessorImage ||
                !state.serial_valid ||
                state.current != '' ||
                state.progress != 0 ||
                state.error_message != undefined
              }
            >
              Connect to Spider X
            </Button>
          </Form.Item>
          {connect_info}
          {connect_warning}
          {connect_error}
        </Card>
        <Card title="Step 3">
          <p>To update Spider X click the button below.{update_info}</p>
          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={startProgram}
              disabled={
                signingOut ||
                !state.serial_valid ||
                state.current != 'wait_program' ||
                state.info_message != undefined ||
                state.error_message != undefined
              }
            >
              Update Spider X
            </Button>
          </Form.Item>
        </Card>
      </Form>
      {progress}
      {status}
      <br />
      <Button disabled={signingOut} type="primary" onClick={logOut}>
        Log Out
      </Button>
      <p />
      {signout}
    </PageHeader>
  );
};

export default Update;
