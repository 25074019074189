import { Auth } from 'aws-amplify';
import { Lambda } from 'aws-sdk';
import { useContext, useEffect, useState } from 'react';

import { FirmwareContext } from '../context/FirmwareContext';

import useAuth from './useAuth';
import useEnv from './useEnv';

const useFirmwaresForContext = (channel) => {
  const [mainFirmwareLink, setMainFirmwareLink] = useState(undefined);
  const [bootloaderFirmwareLink, setBootloaderFirmwareLink] = useState(undefined);
  const [coprocessorFirmwareLink, setCoprocessorFirmwareLink] = useState(undefined);
  const [mainFirmware, setMainFirmware] = useState();
  const [bootloaderFirmware, setBootloaderFirmware] = useState();
  const [coprocessorFirmware, setCoprocessorFirmware] = useState();
  const [mainFetched, setMainHasFetched] = useState(false);
  const [bootloaderFetched, setBootloaderHasFetched] = useState(false);
  const [coprocessorFetched, setCoprocessorHasFetched] = useState(false);
  const [mainFetchFailed, setMainFetchFailed] = useState(undefined);
  const [bootloaderFetchFailed, setBootloaderFetchFailed] = useState(undefined);
  const [coprocessorFetchFailed, setCoprocessorFetchFailed] = useState(undefined);
  const [mainVersionString, setMainVersionString] = useState(undefined);
  const [bootloaderVersionString, setBootloaderVersionString] = useState(undefined);
  const [coprocessorVersionString, setCoprocessorVersionString] = useState(undefined);
  const [env] = useEnv();
  const auth = useAuth();

  useEffect(() => {
    if (env && auth.user) {
      // Invalidate any previous links
      setMainFirmwareLink(undefined);
      setMainHasFetched(false);
      setMainFetchFailed(undefined);
      setMainVersionString(undefined);

      Auth.currentCredentials()
        .then((credentials) => {
          if (!credentials.authenticated) {
            return Promise.reject('Not authenticated');
          }

          return new Lambda({
            apiVersion: '2015-03-31',
            region: 'us-west-2',
            credentials: Auth.essentialCredentials(credentials),
          });
        })
        .then((lambda) => {
          console.log('Calling Lambdas');
          return lambda
            .invoke({
              FunctionName: `firmware-get-${env}`,

              Payload: JSON.stringify({
                pathParameters: {
                  version: 'latest',
                },
                queryStringParameters: {
                  type: 'main',
                  filetype: 'image-only',
                  channel,
                },
              }),
            })
            .promise();
        })
        .then((a) => {
          let payload = JSON.parse(a.Payload);
          if (payload.statusCode < 200 || payload.statusCode >= 300) {
            throw payload.statusCode;
          }
          return JSON.parse(payload.body);
        })
        .then((response) => {
          setMainVersionString(response.metadata.version);
          const major = parseInt(response.metadata.version.split('.')[0]);
          if (isNaN(major) || major < 6) {
            throw 'unsupported version: ' + response.metadata.version;
          }
          setMainFirmwareLink(response.url);
        })
        .catch((e) => {
          console.error('Failed to download the latest main firmware', e);
          setMainFetchFailed('Failed to download the latest main firmware (' + e + ')');
        });
    }
  }, [auth.user]);

  useEffect(() => {
    if (env && auth.user) {
      // Invalidate any previous links
      setBootloaderFirmwareLink(undefined);
      setBootloaderHasFetched(false);
      setBootloaderFetchFailed(undefined);
      setBootloaderVersionString(undefined);

      Auth.currentCredentials()
        .then((credentials) => {
          if (!credentials.authenticated) {
            return Promise.reject('Not authenticated');
          }

          return new Lambda({
            apiVersion: '2015-03-31',
            region: 'us-west-2',
            credentials: Auth.essentialCredentials(credentials),
          });
        })
        .then((lambda) => {
          console.log('Calling Lambdas');
          return lambda
            .invoke({
              FunctionName: `firmware-get-${env}`,

              Payload: JSON.stringify({
                pathParameters: {
                  version: 'latest',
                },
                queryStringParameters: {
                  type: 'bootloader',
                  filetype: 'image-only',
                  channel,
                },
              }),
            })
            .promise();
        })
        .then((a) => {
          let payload = JSON.parse(a.Payload);
          if (payload.statusCode < 200 || payload.statusCode >= 300) {
            throw payload.statusCode;
          }
          return JSON.parse(payload.body);
        })
        .then((response) => {
          setBootloaderFirmwareLink(response.url);
          setBootloaderVersionString(response.metadata.version);
          const major = parseInt(response.metadata.version.split('.')[0]);
          if (isNaN(major) || major < 6) {
            throw 'unsupported version: ' + response.metadata.version;
          }
        })
        .catch((e) => {
          console.error('Failed to download the latest bootloader firmware', e);
          setBootloaderFetchFailed('Failed to download the latest bootloader firmware (' + e + ')');
        });
    }
  }, [auth.user]);

  useEffect(() => {
    if (env && auth.user) {
      // Invalidate any previous links
      setCoprocessorFirmwareLink(undefined);
      setCoprocessorHasFetched(false);
      setCoprocessorFetchFailed(undefined);
      setCoprocessorVersionString(undefined);

      Auth.currentCredentials()
        .then((credentials) => {
          if (!credentials.authenticated) {
            return Promise.reject('Not authenticated');
          }

          return new Lambda({
            apiVersion: '2015-03-31',
            region: 'us-west-2',
            credentials: Auth.essentialCredentials(credentials),
          });
        })
        .then((lambda) => {
          console.log('Calling Lambdas');
          return lambda
            .invoke({
              FunctionName: `firmware-get-${env}`,

              Payload: JSON.stringify({
                pathParameters: {
                  version: 'latest',
                },
                queryStringParameters: {
                  type: 'coprocessor',
                  filetype: 'image-only',
                  channel,
                },
              }),
            })
            .promise();
        })
        .then((a) => {
          let payload = JSON.parse(a.Payload);
          if (payload.statusCode < 200 || payload.statusCode >= 300) {
            throw payload.statusCode;
          }
          return JSON.parse(payload.body);
        })
        .then((response) => {
          setCoprocessorFirmwareLink(response.url);
          setCoprocessorVersionString(response.metadata.version);
        })
        .catch((e) => {
          console.error('Failed to download the latest coprocessor firmware', e);
          setCoprocessorFetchFailed(
            'Failed to download the latest coprocessor firmware (' + e + ')'
          );
        });
    }
  }, [auth.user]);

  useEffect(() => {
    setMainFirmware(undefined);
    if (mainFirmwareLink != undefined) {
      setMainHasFetched(false);
      setMainFetchFailed(undefined);
      fetch(mainFirmwareLink, { method: 'GET', headers: { 'Accept-Encoding': 'gzip' } })
        .then((r) => {
          return r.arrayBuffer();
        })
        .then((fw) => {
          setMainFirmware(fw);
          setMainHasFetched(true);
        })
        .catch((e) => {
          setMainHasFetched(false);
          console.error('Failed to retrieve the latest main firmware', e);
          setMainFetchFailed('Failed to retrieve the latest main firmware (' + e + ')');
        });
    }
  }, [mainFirmwareLink]);

  useEffect(() => {
    setBootloaderFirmware(undefined);
    if (bootloaderFirmwareLink != undefined) {
      setBootloaderHasFetched(false);
      setBootloaderFetchFailed(undefined);
      fetch(bootloaderFirmwareLink, { method: 'GET', headers: { 'Accept-Encoding': 'gzip' } })
        .then((r) => {
          return r.arrayBuffer();
        })
        .then((fw) => {
          setBootloaderFirmware(fw);
          setBootloaderHasFetched(true);
        })
        .catch((e) => {
          setBootloaderHasFetched(false);
          console.error('Failed to retrieve the latest bootloader firmware', e);
          setBootloaderFetchFailed('Failed to retrieve the latest bootloader firmware (' + e + ')');
        });
    }
  }, [bootloaderFirmwareLink]);

  useEffect(() => {
    setCoprocessorFirmware(undefined);
    if (coprocessorFirmwareLink != undefined) {
      setCoprocessorHasFetched(false);
      setCoprocessorFetchFailed(undefined);
      fetch(coprocessorFirmwareLink, { method: 'GET', headers: { 'Accept-Encoding': 'gzip' } })
        .then((r) => {
          return r.arrayBuffer();
        })
        .then((fw) => {
          setCoprocessorFirmware(fw);
          setCoprocessorHasFetched(true);
        })
        .catch((e) => {
          setCoprocessorHasFetched(false);
          console.error('Failed to retrieve the latest coprocessor firmware', e);
          setCoprocessorFetchFailed(
            'Failed to retrieve the latest coprocessor firmware (' + e + ')'
          );
        });
    }
  }, [coprocessorFirmwareLink]);

  return [
    mainFirmware,
    mainFetched,
    bootloaderFirmware,
    bootloaderFetched,
    coprocessorFirmware,
    coprocessorFetched,
    mainFetchFailed,
    bootloaderFetchFailed,
    coprocessorFetchFailed,
    mainVersionString,
    bootloaderVersionString,
    coprocessorVersionString,
  ];
};

const useFirmwares = () => {
  const v = useContext(FirmwareContext);

  return v;
};
export { useFirmwaresForContext };
export default useFirmwares;
