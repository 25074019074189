import { Spin } from 'antd';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import Login from './Login';
import PropTypes from 'prop-types';

const AuthenticatorWrap = ({ children }) => {
  const [authState, setAuthState] = useState(undefined);

  // Check if user is logged in when we arrive
  useEffect(() => {
    const isLoggedIn = async () => {
      await Auth.currentAuthenticatedUser()
        .then(() => {
          setAuthState(true);
        })
        .catch(() => {
          setAuthState(false);
        });
    };
    isLoggedIn();
  }, []);

  return (
    <React.Fragment>
      {authState === true && children}
      {authState === false && <Login onLogin={setAuthState} />}
      {authState === undefined && <Spin />}
    </React.Fragment>
  );
};

AuthenticatorWrap.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AuthenticatorWrap;
