import React, { createContext } from 'react';
import { useUpdateForContext } from '../hooks/useUpdate';
import PropTypes from 'prop-types';

export const UpdateContext = createContext([null, null, null]);

export const Provider = ({ children }) => {
  const update = useUpdateForContext();

  return <UpdateContext.Provider value={update}>{children}</UpdateContext.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};
